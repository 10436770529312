/*
David Paredes website
author: Alejandro Cabrera
date: 18/07/2017
*/

$.ajax({
	url: 'js/utilities.js',
	dataType: 'script'
});

$(document).ready(function(){
/* Atributos */
	var	head = $('head'),
		body = $('body'),
		style = $('style#insertcss'),
		page = $('.page'),
		header = $('header'),
		main = $('main'),
		footer = $('footer'),
		areas = [
					$('.area-0'),
					$('.area-1'),
					$('.area-2'),
					$('.area-3'),
					$('.area-4')
				],
		short = $('.short'),
		goto = $('.goto'),
		projectThumb = $('.pr_thumb'),
		trim_obj = $.find('.event-title'),
		iframe = $.find('iframe');
	
/* Funciones */
	// Devuelve el corte de un string a un máximo de caracteres
	/// Recorriendo un array
	function trimmerArray(j, k){
		var j = j - 3;
		$.each(k, function(i, v){
			var t = v.html();
			v.each(function(){
				t = t.substring(0, j) +'...';
				$(this).html(t);
			});
		});
	}
	/// Recorriendo un objeto
	function trimmerObj(j, k){
		var j = j - 3;
		k.each(function(){
			var t = $(this).html();
			if(t.length >= j)
			{
				t = t.substring(0, j) +'...';
			}
			$(this).html(t);
		});
	}
	/// Convirtiendo a arrays los objetos
	function newTrim(j, k){
		var f = [],
			w = winX;
		$.each(k, function(i, e){
			f.push(e);
		});
		j = j - 3;
		$.each(f, function(i, e){
			var t = e.innerHTML;
			if(t.length >= j)
			{
				t = t.substring(0, j) +'...';
				//console.log(t +' +'+ (j + 3));
			} else {
				t = e.innerHTML;
				//console.log(t +' -'+ (j + 3))
			}
			$(this).html(t)
		});
	}
	// Devuelve el corte de un string a un máximo de caracteres en función de la vista
	function trimmer(e){
		if(isShorterX(mobi))
		{
			newTrim(30, e);
			console.log(mobi, getLength($('.event-title:first').text()));
		} else if(isShorterX(tabl)){
			newTrim(20, e);
			console.log(tabl, getLength($('.event-title:first').text()));
		} else if(isShorterX(desk)){
			newTrim(25, e);
			console.log(desk, getLength($('.event-title:first').text()));
		} else if(isShorterX(lapt)){
			newTrim(30, e);
			console.log(lapt, getLength($('.event-title:first').text()));
		} else if(isShorterX(port)){
			if(isShorterY(900))
			{
				newTrim(50, e);
			} else if(isShorterY(1000)){
				newTrim(30, e);
			} else {
				newTrim(40, e);
			}
			console.log(port, getLength($('.event-title:first').text()));
		}
	}
	// Reemplaza las etiquetas de encabezados de artículo y devuelve un corte de texto
	function shorties(n, c, l){
		var o = short.find('a').children(),
			t = o.html();
		if(t != undefined)
		{
			if(!isShorterX(mobi))
			{
				o.each(function(){
					var t = $(this).html();
					if(t.length >= l)
					{
						var f = t.substring(0, l) + '...';
					} else {
						var f = t.substring(0, l);
					}
					$(this).replaceWith('<h'+ n +' class="short">'+ f +'</h'+ n +'>');
					console.log(t.length);
				});
				var o = short.find('h'+ n);
			} else {
				o.each(function(){
					var t = $(this).html(),
						f = t.substring(0, t.length);
					$(this).replaceWith('<h'+ c +'>'+ f +'</h'+ c +'>');
				});
				var o = short.find('h'+ c);
			}
		}
	}
	// Ajusta el alto de página tras interpretar el alto del viewport
	function fixHeight(y, z, q){
		var x = getOuterHeight(z),
			n = getHeight(q);
		//if(q == undefined){ n = 0; }
		if(isShorterY(900))
		{
			if(isShorterX(mobi))
			{
				//console.log('fixHeight <= '+ mobi);
				page.css({
					height: 'auto',
					minHeight: 'auto'
				});
				header.find('.cell').height(getOuterHeight(header.find('nav')));
			} else if(isShorterX(tabl)){
				//console.log('fixHeight <= '+ tabl);
				page.css({
					height: 'auto',
					minHeight: y + x
				});
				header.find('.cell').height('');
			} else if(isShorterX(desk)){
				//console.log('fixHeight <= '+ desk);
				page.css({
					height: 'auto',
					minHeight: n
				});
				header.find('.cell').height('');
			} else {
				//console.log('fixHeight <= 900y');
				page.css({
					height: '',
					minHeight: y + (x + (n * .5))
				});
				header.find('.cell').height('');
			}
		} else {
			//console.log('fixHeight >= 900y');
			page.css({
				height: '',
				minHeight: ''
			});
			if(isShorterX(mobi))
			{
				header.find('.cell').height(getOuterHeight(header.find('nav')));
			}
		}
	}
	// Ajusta la cuadrícula en función de las vistas
	function responsiveGrid(){
		/// Reinicializa los valores para el ajuste de cuadrícula
		function resetGrid(f){
			$.each(f, function(){
				$(this).css({
					marginLeft: '',
					marginTop: ''
				});
			});
		}
		/// Ajusta el desplazamamiento lateral de los objetos para respetar la cuadrícula madre
		function fixGrid(e, f){
			if(isShorterX(mobi))
			{
				//console.log('fixGrid 1');
				f.css({
					marginLeft: 0,
					marginTop: 0
				});
			} else if(isShorterX(desk)){
				//console.log('fixGrid 2');
				if(isUrl('projects'))
				{
					f.css({
						marginLeft: '',
						marginTop: ''
					});
				} else {
					f.css({
						marginLeft: 0,
						marginTop: getOuterHeight(e)
					});
				}
			} else {
				//console.log('fixGrid 3');
				if(isUrl('projects'))
				{
					f.css({
						marginLeft: '',
						marginTop: ''
					});
				} else {
					f.css({
						marginLeft: getOuterWidth(e),
						marginTop: 0
					});
				}
			}
		}
		if(isShorterX(mobi))
		{
			resetGrid(areas[1]);
			resetGrid(areas[2]);
		} else if(isShorterX(tabl)){
			fixGrid(header.find('section'), areas[1]);
			fixGrid(header.find('section'), areas[2]);
		} else {
			fixGrid(header.find('section'), areas[1]);
			resetGrid(areas[2]);
		}
	}
	// Ajusta el alto del elemento
	function fixBefore(o, d){
		var a = d.children(),
			val = [],
			tag = [];
		// Obtiene los valores de cada elemento y los guarda en un array
		o.each(function(){
			val.push($(this).outerHeight());
		})
		// Obtiene las etiquetas de cada elemento y los guarda en un array
		a.each(function(){
			tag.push($(this).prop('tagName').toLowerCase());
		});
		// Log de valores y etiquetas
		//console.log(val)
		//console.log(tag)
		// Inserta las reglas dinámicas en el <head> (ajustes para 6)
		head.append(
			'<style id="insertcss" type="text/css">\n'+
			'.area-1 > .cell:nth-child(1) * '+ tag[0] +':before {'+
			'height: calc(100% - ('+ val[0] +'px + '+ rem +'px));'+
			'}\n'+
			'\n'+
			'.area-1 > .cell:nth-child(2) * '+ tag[1] +':before {'+
			'height: calc(100% - ('+ val[1] +'px + '+ rem +'px));'+
			'}\n'+
			'\n'+
			'.area-2 > .cell:nth-child(1) * '+ tag[2] +':before {'+
			'height: calc(100% - ('+ val[2] +'px + '+ rem +'px));'+
			'}\n'+
			'\n'+
			'.area-2 > .cell:nth-child(2) * '+ tag[3] +':before {'+
			'height: calc(100% - ('+ val[3] +'px + '+ rem +'px));'+
			'}\n'+
			'\n'+
			'.area-2 > .cell:nth-child(3) * '+ tag[4] +':before {'+
			'height: calc(100% - ('+ val[4] +'px + '+ rem +'px));'+
			'}\n'+
			'\n'+
			'.area-3 * '+ tag[5] +':before {'+
			'height: calc(100% - ('+ val[5] +'px + '+ rem +'px));'+
			'}\n'+
			'\n</style>'
		);
		// Comprueba si se genera etiquetado extra y elimina las redundancias
		if($('style#insertcss').length > 1)
		{
			head.find($('style#insertcss')).first().remove();
		}
	}
	// Compone el menú de navegación responsive
	function navigate(){
		var nav = $('.nav-menu'),
			tri = $('.nav-trigger'),
			ico = tri.find('.nav--icon'),
			heading = header.find('h1'),
			disp = body.width(),
			winY = $(window).innerHeight();
		/// Devuelve el valor de 'disp' en función del ancho del viewport
		function getDisp(){
			if(isShorterX(mobi))
			{
				return disp = disp - (tri.outerWidth() + rem);
			} else if(isShorterX(tabl)){
				return disp = disp * .666666;
			} else {
				return disp = disp / 3;
			}
		} getDisp();
		/// Comprueba si existe el atributo 'data-menu' en 'd'
		function getData(d){ return d.data('data-menu') == true; }
		/// Reinicializa los arreglos de 'e'
		function resetStyle(e){ e.attr('style', '').removeClass('cell'); }
		/// Coloca la navegaciób a la derecha
		function navRight(){
			nav.css({
				right: -disp,
				width: disp - (rem * 3.4),
				height: winY - (rem * 2),
				marginRight: rem,
				marginTop: rem,
				opacity: 0
			}).addClass('cell');
		}
		/// Coloca la navegación a la izquierda
		function navLeft(){
			nav.css({
				left: -disp,
				width: disp - (rem * 1.2),
				height: winY - (rem * 2),
				marginLeft: rem,
				marginTop: rem,
				opacity: 0
			}).addClass('cell');
		}
		/// Ajusta la opacidad de 'o' a 'n'
		function fixOpacity(o, n){
			o.animate({
				opacity: n
			}, 330);
		}
		/// Ajusta la colocación de la navegación
		function fixMenu(){
			resetStyle(nav)
			if(isShorterX(mobi))
			{
				navRight();
			} else if(isShorterX(desk)){
				navLeft();
			} else {
				resetStyle(nav);
			}
		} fixMenu();
		$(window).on('resize', function(){
			winY = $(window).innerHeight();
			fixMenu();
		});
		/// Muestra la navegación
		function showNav(){
			if(isShorterX(mobi))
			{
				page.addClass('navigate')
					.animate({
						right: disp - (rem * 2.2)
					}, 440, function(){
						page.data('data-menu', true).attr('data-menu', true);
					});
				nav.animate({
					right: 0,
					opacity: 1
				}, 330);
				$.each(areas, function(i, v){
					if(i > 0)
					{
						fixOpacity(v, .25);
					} else {
						fixOpacity(v, 1);
					}
				});
				ico.addClass('navigate');
			} else {
				page.addClass('navigate')
					.animate({
						left: disp
					}, 330, function(){
						page.data('data-menu', true).attr('data-menu', true);
					});
				nav.animate({
					left: 0,
					opacity: 1
				}, 440);
				$.each(areas, function(i, v){
					if(i > 0)
					{
						fixOpacity(v, .25);
					} else {
						fixOpacity(v, 1);
					}
				});
				ico.addClass('navigate');
			}
		}
		/// Oculta la navegación
		function hideNav(){
			if(isShorterX(mobi))
			{
				page.animate({
						right: 0
					}, 330, function(){
						page.data('data-menu', false).attr('data-menu', false);
						page.removeClass('navigate');
					});
				nav.animate({
					right: -disp,
					opacity: 0
				}, 330);
				$.each(areas, function(i, v){
					if(i > 0)
					{
						fixOpacity(v, 1);
					} else {
						fixOpacity(v, 1);
					}
				});
				ico.removeClass('navigate');
			} else {
				page.animate({
						left: 0
					}, 330, function(){
						page.data('data-menu', false).attr('data-menu', false);
						page.removeClass('navigate');
					});
				nav.animate({
					left: -disp,
					opacity: 0
				}, 330);
				$.each(areas, function(i, v){
					if(i > 0)
					{
						fixOpacity(v, 1);
					} else {
						fixOpacity(v, 1);
					}
				});
				ico.removeClass('navigate');
			}
		}
		/// Dispara la animación de la navegación
		tri.on('click', function(){
			if(!getData(page))
			{
				body.css('overflow', 'hidden');
				showNav();
			} else {
				body.css('overflow', '');
				hideNav();
			}
		});
	}
	// Compensa la altura de "o" para falsear una position:absolute del footer
	function layout(o, h, k){
		var a = h.height(),
			b = k.outerHeight();
		if(!isUrl('index'))
		{
			o.css({
				minHeight: page.height() - (a + b) - (rem*4)
			});
		}
	}
	// Ajusta el alto del elemento en relación a dos valores dados
	function displayLayout(o, h, k){
		var a = h.height(),
			p = o.find('img');
		if(!isUrl('index'))
		{
			o.css('height', k - a - (rem*2));
			p.height(o.outerHeight()*1.08);
		}
	}
	// Alinea proporcionalmente los elementos al centro vertical de su padre
	function alignThumbs(o){
		o.each(function(){
			var n = $(this).parent().outerWidth().toFixed(3),
				n = parseFloat(n),
				d = $(this).width().toFixed(3),
				d = parseFloat(d),
				r = ((d/n)*100).toFixed(3),
				r = parseFloat(r);
			$(this).css('left', '-'+(r-100)/2+'%');
		});
	}
	// Anima el logo reemplazando la src de la img
	function animLogo(o){
		var src = o.attr('src'),
			r = 'css/img/',
			f = ['.png', '.gif'];
		function png(){
			return src.indexOf(f[0]) >= 0;
		}
		function replaceElement(){
			o.each(function(){
				var w = $(this).width();
				$(this).attr('src', r+'brand_anim.gif').css({
					maxWidth: w
				});
			}
		)}
		if(o.length > 0 && o.hasClass('brand'))
		{
			o.hover(function(){
				replaceElement()
			},
					function(){
				o.attr('src', src).css({maxWidth: ''});
			});
		}
	}
	// Crea una envoltura responsiva para los iframe de video
	function responsiveVideo(t, s){
		var o = '<'+t+'></'+t+'>',
			e = $('iframe[src*="'+s+'"]'),
			x = e.parent().width(),
			y = x/1.778;
		e.each(function(){
			$(this)
				.wrap(o)
				.css({
					position: 'absolute',
					top: 0,
					left: 0,
					width: 100+'%',
					height: 100+'%'
				})
				.attr('height', y).attr('width', 100+'%')
				.parent().addClass('video-wrapper').height(y);
		});
	}
	
	/*var iframe = $('iframe[src*="soundcloud"]'),
		y = iframe.attr('height'),
		y = parseInt(y),
		s = iframe.attr('src');
	console.log(y, s)
	if(s.indexOf('soundcloud') > 0){
		console.log(1);
	}*/
	
	var sc = $('iframe[src*="soundcloud"]'),
		src = sc.attr('src');
	sc.attr('height', '600');
	//sc.attr('width', '50%');
	var pos = sc.is(':first-child');
	function prependIframe(){
		if(!pos)
		{
			sc.each(function(){
				$(this).prependTo($(this).parent());
			});
		} else {
			sc.each(function(){
				$(this).prependTo($(this).parent());
			});
		}
	}
	// I know that the code could be better.
// If you have some tips or improvement, please let me know.

	/*$(".parallax").each(function() {
		var img = $(this),
			imgParent = $(this).parent();
		function parallaxImg() {
			var speed = img.data("speed");
			var imgY = imgParent.offset().top;
			var winY = $(this).scrollTop();
			var winH = $(this).height();
			var parentH = imgParent.innerHeight();

			// The next pixel to show on screen
			var winBottom = winY + winH;

			// If block is shown on screen
			if (winBottom > imgY && winY < imgY + parentH) {
				// Number of pixels shown after block appear
				var imgBottom = (winBottom - imgY) * speed;
				// Max number of pixels until block disappear
				var imgTop = winH + parentH;
				// Porcentage between start showing until disappearing
				var imgPercent = imgBottom / imgTop * 100 + (50 - speed * 50);
			}
			img.css({
				top: imgPercent + "%",
				transform: "translate(-0%, -" + imgPercent + "%)"
			});
		}
		$(document).on({
			scroll: function() {
				parallaxImg();
			},
			ready: function() {
				parallaxImg();
			}
		});
	});*/

	
	
	$('.parallax').each(function(){
		var i = $(this),
			p = $(this).parent();
		function displayParallax(){
			var v = i.data('speed'),
				pY = p.offset().top,
				iY = i.scrollTop(),
				y = i.height(),
				n = p.innerHeight(),
				bottom = pY+y;
			if(bottom > pY && iY < pY+n)
			{
				var iBottom = (bottom - pY);
			}
			console.log(pY, iY, y, n, bottom);
			$(document).on({
				scroll: function(){
					i.css('top', iY);
					console.log(pY);
				}
			})
		}
		displayParallax()
	});
	

	
/* Ejecución */
	alignThumbs(projectThumb);
	responsiveVideo('div', 'vimeo');
	animLogo($('img[src*=static]'));
	displayLayout($('.pr_display'), header, winY);
	layout(main, header, footer);
	fixHeight(winY, header, areas[1]);
	responsiveGrid();
	shorties(5, 3, 17);
	fixBefore(goto.children(), goto);
	navigate();
	trimmer(trim_obj);
	// Window resize event
	$(window).on('resize', function(){
		var winX = $(window).innerWidth(),
			winY = $(window).innerHeight();
		responsiveGrid();
		navigate();
		setTimeout(function(){
			location.reload();
		}, 100);
	});
	console.log(winX, winY, setRem(tabl), isUrl('projects'))
});
